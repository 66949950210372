@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.anton {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lds-heart,
.lds-heart div,
.lds-heart div:after,
.lds-heart div:before {
  box-sizing: border-box;
}
.lds-heart {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  transform-origin: center;
}
.lds-heart div {
  top: 7px;
  left: 7px;
  position: absolute;
  width: 8px;
  height: 8px;
  background: currentColor;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background: currentColor;
}
.lds-heart div:before {
  left: -6px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -6px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
